<template>
  <div>
    <v-row>
      <v-col>
        <v-text-field
          v-model="filter.date_from"
          type="date"
          :label="$t('from')"
          clearable
          :max="today"
          :placeholder="$t('date_placeholder')"
          :error="dateError"
          @keyup.enter="(e) => e.target.blur()"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="filter.date_till"
          type="date"
          :label="$t('till')"
          clearable
          :max="today"
          :placeholder="$t('date_placeholder')"
          :error="dateError"
          @keyup.enter="(e) => e.target.blur()"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-select
      v-model="filter.brand"
      :label="$t('payment_type')"
      :items="brands"
      clearable
    ></v-select>

    <v-select
      v-model="filter.dispenser"
      :label="$t('dispenser')"
      :items="dispensers"
      clearable
    ></v-select>

    <v-select
      v-model="filter.asset"
      :label="$tc('opts')"
      :items="assets"
      clearable
    ></v-select>

    <v-select
      v-model="filter.fuel"
      :label="$t('fuel_type')"
      :items="fuels"
      clearable
    ></v-select>

    <v-btn-toggle v-model="filter.operator" borderless dense mandatory>
      <v-btn value="less-than">
        <v-icon small>fa-less-than-equal</v-icon>
      </v-btn>
      <v-btn value="equals">
        <v-icon small>fa-equals</v-icon>
      </v-btn>
      <v-btn value="greater-than">
        <v-icon small>fa-greater-than-equal</v-icon>
      </v-btn>
      <v-btn value="between">
        <v-icon small>fa-less-than</v-icon>
        <v-icon small class="ml-1">fa-greater-than</v-icon>
      </v-btn>
    </v-btn-toggle>

    <v-row>
      <v-col>
        <v-text-field
          v-model.number="debouncedAmount1"
          type="number"
          min="0"
          :label="
            filter.operator === 'between' ? $t('minimum') : $t(filter.operator)
          "
          clearable
          @keyup.enter="(e) => e.target.blur()"
        ></v-text-field>
      </v-col>
      <v-col v-if="filter.operator === 'between'">
        <v-text-field
          v-model.number="debouncedAmount2"
          type="number"
          min="0"
          :label="$t('maximum')"
          clearable
          @keyup.enter="(e) => e.target.blur()"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-text-field
      v-model.trim="debouncedSearch"
      :label="$t('search_full')"
      clearable
      @keyup.enter="(e) => e.target.blur()"
    ></v-text-field>

    <v-checkbox
      v-model="filter.zeroTransactions"
      :label="$t('only_zero_transactions')"
    ></v-checkbox>

    <v-btn text @click="resetFilter">{{ $t("clear") }}</v-btn>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { debounce } from "lodash-es";
import { mapActions, mapState } from "vuex";
import TransactionsFilterController from "@/components/Network/SidebarTabs/Transactions/TransactionsFilterController";

export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      today: null,
      brands: [],
      dispensers: [],
      assets: [],
      fuels: [],
    };
  },
  computed: {
    ...mapState({
      selectedSite: (state) => state.site.selectedSite,
      transactions: (state) => state.site.transactions,
    }),
    debouncedAmount1: {
      get() {
        if (!this.filter.amount1) {
          return null;
        }
        return this.filter.amount1 / 1000;
      },
      set: debounce(function (value) {
        this.filter.amount1 = value * 1000;
      }, 200),
    },
    debouncedAmount2: {
      get() {
        if (!this.filter.amount2) {
          return null;
        }
        return this.filter.amount2 / 1000;
      },
      set: debounce(function (value) {
        this.filter.amount2 = value * 1000;
      }, 200),
    },
    debouncedSearch: {
      get() {
        return this.filter.search;
      },
      set: debounce(function (value) {
        this.filter.search = value || null;
      }, 200),
    },
    dateError() {
      return this.filter.date_from > this.filter.date_till;
    },
  },
  watch: {
    filter: {
      deep: true,
      handler(value) {
        if (value.operator === "between") {
          return;
        }
        this.filter.amount2 = null;
      },
    },
  },
  async mounted() {
    this.today = dayjs().format("YYYY-MM-DD");

    this.brands = await this.getBrands();
    this.dispensers = await this.getDispensers();
    this.assets = await this.getOpts();
    this.fuels = await this.getFuels();
  },
  methods: {
    ...mapActions(["getBrands", "getDispensers", "getOpts", "getFuels"]),
    resetFilter() {
      this.filter = TransactionsFilterController.reset(this.filter);
    },
  },
};
</script>
