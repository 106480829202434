<template>
  <aside v-if="selectedSite">
    <v-card class="site-details">
      <v-card-text>
        <v-row>
          <v-col cols="10">
            <p class="text--primary mb-0 site-details-header">
              <v-chip
                :class="`badge badge-small badge-${selectedSite.status}`"
                x-small
              >
                {{ $t(`severity_${selectedSite.status}`) }}
              </v-chip>
              <span class="ml-2 site-name">{{ selectedSite.name }}</span>
              <v-btn
                v-if="
                  selectedSite.location.latitude &&
                  selectedSite.location.longitude
                "
                icon
                x-small
                class="ml-1"
                @click="showOnMap"
              >
                <v-icon>fas fa-map-pin</v-icon>
              </v-btn>
            </p>
            <p class="text--disabled mb-0">
              {{ selectedSite.address | address }}<br />
              <small>{{ selectedSite.micrelec_id }}</small>
            </p>
          </v-col>
          <v-col cols="2" class="text-right">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon small>fa-ellipsis-v</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="$productAlarms"
                  @click="showDialog = 'prices'"
                >
                  {{ $t("fuel_prices") }}
                </v-list-item>
                <v-list-item @click="showDialog = 'ticket-template'">
                  {{ $t("ticket_template") }}
                </v-list-item>
                <v-list-item
                  v-if="$productAlarms"
                  @click="doActionOnSiteController('sc/sales_period_closure')"
                >
                  {{ $t("actions_sc/sales_period_closure") }}
                </v-list-item>
                <v-list-item
                  v-if="$productAlarms"
                  @click="doActionOnSiteController('sc/reboot')"
                >
                  {{ $t("actions_sc/reboot") }}
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-progress-circular
      v-if="loading"
      indeterminate
      color="primary"
      class="loading"
    ></v-progress-circular>

    <alarms v-else-if="activeTab === 'alarms'"></alarms>

    <assets v-else-if="activeTab === 'assets'"></assets>

    <transactions v-else-if="activeTab === 'transactions'"></transactions>

    <v-bottom-navigation v-model="activeTab" grow>
      <v-btn value="alarms">
        <span>
          <v-icon left>fa-exclamation-triangle</v-icon>
          {{ $tc("alarms", 2) }}
        </span>
      </v-btn>
      <v-btn value="assets">
        <span>
          <v-icon left>$vuetify.icons.opt</v-icon>
          {{ $t("assets") }}
        </span>
      </v-btn>
      <v-btn value="transactions">
        <span>
          <v-icon left>fa-file-invoice-dollar</v-icon>
          {{ $t("transactions") }}
        </span>
      </v-btn>
    </v-bottom-navigation>

    <fuel-prices-dialog
      v-if="showDialog === 'prices'"
      :show-dialog="!!showDialog"
      @close-dialog="showDialog = false"
    ></fuel-prices-dialog>

    <ticket-template-dialog
      v-if="showDialog === 'ticket-template'"
      :show-dialog="!!showDialog"
      @close-dialog="showDialog = false"
    ></ticket-template-dialog>
  </aside>
</template>

<script>
import Vue from "vue";
import { mapActions, mapState } from "vuex";
import EventBus from "@/services/EventBus";
import FuelPricesDialog from "./Dialogs/FuelPricesDialog";
import TicketTemplateDialog from "./Dialogs/TicketTemplateDialog";
import Alarms from "./SidebarTabs/Alarms/Alarms";
import Assets from "./SidebarTabs/Assets/Assets";
import Transactions from "./SidebarTabs/Transactions/Transactions";

export default {
  name: "SiteDetails",
  components: {
    Alarms,
    Assets,
    FuelPricesDialog,
    TicketTemplateDialog,
    Transactions,
  },
  props: {
    siteId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      showDialog: false,
      activeTab: "alarms",
    };
  },
  computed: {
    ...mapState({
      selectedSite: (state) => state.site.selectedSite,
      sites: (state) => state.site.sites,
    }),
  },
  watch: {
    siteId: {
      handler() {
        this.selectSiteFromRouteParameter();
      },
    },
    activeTab(value) {
      if (value === this.$route.query.tab) {
        return;
      }
      this.$router.replace({ query: { tab: value } });
    },
  },
  mounted() {
    // Select site from route parameter
    // Immediate watcher doesn't seem to fire in some cases, so call the function on mount
    this.selectSiteFromRouteParameter();

    // Check if user visits this page directly by clicking on a notification
    // If so, mark alert as read
    const alerts = this.$route.query.alerts;
    if (alerts) {
      this.markAlertsAsRead(alerts.split(","));
    }

    // Restore active tab from query
    if (this.$route.query.tab) {
      this.activeTab = this.$route.query.tab;
    }
  },
  destroyed() {
    this.clearSelectedSite();
    Vue.prototype.$sockets.unsubscribeFromSite();
  },
  methods: {
    ...mapActions([
      "clearSelectedSite",
      "doAction",
      "getAssets",
      "getSiteControllerId",
      "markAlertsAsRead",
      "selectSite",
    ]),
    selectSiteFromRouteParameter() {
      this.selectSite(this.siteId);
      this.loading = true;
      this.getAssets().then(() => {
        this.loading = false;
        Vue.prototype.$sockets.subscribeToSite(this.selectedSite.id);
      });
    },
    async doActionOnSiteController(action) {
      const siteControllerId = await this.getSiteControllerId();
      EventBus.$emit("action", action, siteControllerId);
    },
    showOnMap() {
      EventBus.$emit("focus-map", this.selectedSite);
    },
  },
};
</script>
