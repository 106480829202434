<template>
  <div
    class="transactions"
    :class="{
      'filter-enabled': filterIsEnabled,
      'filter-open': filterIsOpen,
      'filter-pinned': filterPinned,
    }"
  >
    <v-card>
      <v-expansion-panels v-model="filterOpen" class="transactions-filter">
        <v-expansion-panel>
          <v-expansion-panel-header class="pl-3">
            <span>
              <v-btn
                class="mr-3"
                icon
                x-small
                @click.stop="filterPinned = !filterPinned"
              >
                <v-icon
                  x-small
                  :color="filterPinned ? 'primary' : 'grey lighten-1'"
                >
                  fas fa-thumbtack
                </v-icon>
              </v-btn>
              <v-icon
                x-small
                class="mr-2"
                :color="filterIsEnabled ? 'primary' : 'grey lighten-1'"
              >
                fas fa-filter
              </v-icon>
              {{ $t("filter") }}
            </span>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <transactions-filter :filter="filter"></transactions-filter>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-list-item
        v-for="t in transactionsByDate"
        :key="t.id"
        class="py-3 with-separator"
        :class="[
          t.amount === 0 && t.status !== 'No error' ? 'null-transaction' : '',
          t.addedAsync ? 'added-async' : '',
        ]"
        @click="showDialogForTransaction(t)"
      >
        <div>
          <span>{{ t.date | date }}</span>
          <span>
            {{ t.date | time }}
            <span class="text--disabled">
              {{ $tc("pumps") }} {{ t.dispenser_number }}
            </span>
          </span>
        </div>
        <div>
          <span v-if="t.opt_number">{{ t.opt_number }}</span>
          <span
            v-if="!['cash', 'none'].includes(t.brand.toLowerCase())"
            class="text--disabled"
          >
            {{ t.cardref | cardref }}
          </span>
        </div>
        <div>
          <span>{{ t.amount | money(2) }}</span>
          <span class="text--disabled">
            {{ t.brand }}
          </span>
        </div>
        <div>
          <span v-if="t.fuel.name.toLowerCase() === 'cng'">
            {{ t.volume | weightTransaction }}
          </span>
          <span v-else>{{ t.volume | volumeTransaction(2, t.unit) }}</span>
          <span class="text--disabled">
            {{ t.fuel.name }}
          </span>
        </div>
        <p v-if="t.amount === 0 && t.status !== 'No error'" class="my-0">
          <small class="font-weight-bold">{{ t.status }}</small>
        </p>
      </v-list-item>

      <v-btn v-if="allowLoadMore" block plain x-large @click="loadMore">
        {{ $t("load_more") }}
      </v-btn>

      <v-list-item v-if="transactions.length === 0" class="py-3 text--disabled">
        {{ $t("no_results") }}
      </v-list-item>
    </v-card>

    <transaction-details-dialog
      v-if="showDialog"
      :show-dialog="showDialog"
      :transaction="dialogTransaction"
      @close-dialog="showDialog = false"
    ></transaction-details-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import TransactionDetailsDialog from "./Dialogs/TransactionDetailsDialog";
import TransactionsFilter from "./TransactionsFilter";
import TransactionsFilterController from "./TransactionsFilterController";

export default {
  components: { TransactionDetailsDialog, TransactionsFilter },
  data() {
    return {
      filterPinned: false,
      filterOpen: false,
      filter: TransactionsFilterController.default(),
      page: 1,
      loading: false,
      showDialog: false,
      dialogTransaction: null,
    };
  },
  computed: {
    ...mapGetters(["transactionsByDate"]),
    ...mapState({
      transactions: (state) => state.site.transactions,
      transactionsPages: (state) => state.site.transactionsPages,
    }),
    filterIsEnabled() {
      return TransactionsFilterController.enabled(this.filter);
    },
    filterIsOpen() {
      // Expansion panel's v-model corresponds to a zero-based index of the currently opened content
      return this.filterOpen === 0;
    },
    allowLoadMore() {
      return this.transactions.length > 0 && this.page < this.transactionsPages;
    },
  },
  watch: {
    filter: {
      deep: true,
      immediate: true,
      handler() {
        this.page = 1;
        this.loadTransactions();
      },
    },
  },
  methods: {
    ...mapActions(["getTransactions"]),
    loadTransactions() {
      this.getTransactions({ page: this.page, filter: this.filter });
    },
    loadMore() {
      this.page++;
      this.loadTransactions();
    },
    showDialogForTransaction(transaction) {
      this.showDialog = true;
      this.dialogTransaction = transaction;
    },
  },
};
</script>
