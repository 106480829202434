<template>
  <v-expansion-panel class="has-tree">
    <v-expansion-panel-header>
      {{ name }}
    </v-expansion-panel-header>

    <v-expansion-panel-content>
      <div class="tree">
        <asset
          v-for="asset in group"
          :key="asset.assetId"
          :asset="asset"
        ></asset>
      </div>

      <alert
        v-for="alert in alertsForGroup"
        :key="alert.id"
        :alert="alert"
        :show-site-name="false"
        open-details
        small-badge
        class="mt-3"
      ></alert>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapGetters } from "vuex";
import Alert from "@/components/Global/Alert";
import Asset from "@/components/Network/SidebarTabs/Assets/Asset.vue";

export default {
  components: { Asset, Alert },
  props: {
    name: {
      type: String,
      required: true,
    },
    group: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["alertsForAsset"]),
    alertsForGroup() {
      const alerts = [];
      this.group.forEach((asset) => {
        alerts.push(...this.alertsForAsset(asset.assetId));
      });
      return alerts;
    },
  },
};
</script>
