<template>
  <v-dialog v-model="showDialogLocal" width="500">
    <v-card class="ticket-template-dialog" :class="{ 'text-center': loading }">
      <v-toolbar flat class="pl-2 pr-2 py-0">
        <v-toolbar-title>
          {{ $t("ticket_template") }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="showDialogLocal = false">
          <v-icon>fa-times</v-icon>
        </v-btn>
      </v-toolbar>

      <v-progress-circular
        v-if="loading"
        color="primary"
        indeterminate
        class="my-5 mx-5"
      ></v-progress-circular>

      <v-card-text v-if="!loading && mode === 'view'">
        <ul>
          <li v-for="(line, i) in ticketTemplate" :key="i" class="mb-2">
            {{ line[2] }}
          </li>
        </ul>
      </v-card-text>

      <v-card-actions v-if="!loading && mode === 'view'">
        <v-btn block text @click="mode = 'edit'">
          {{ $t("edit") }}
        </v-btn>
      </v-card-actions>

      <v-card-text v-if="!loading && mode === 'edit'">
        <div class="group">
          <p class="mb-0">{{ $t("general") }}</p>
          <v-text-field
            v-for="(line, i) in ticketTemplateLines('general')"
            :key="`general${i}`"
            v-model.trim="line[2]"
            hide-details
            :class="{ 'pt-0 mt-0': i === 0 }"
            :placeholder="`${$t('line')} ${i + 1}`"
          ></v-text-field>
        </div>

        <div class="group">
          <p class="mb-0">{{ $t("header") }}</p>
          <v-text-field
            v-for="(line, i) in ticketTemplateLines('header')"
            :key="`header${i}`"
            v-model.trim="line[2]"
            hide-details
            :class="{ 'pt-0 mt-0': i === 0 }"
            :placeholder="`${$t('line')} ${i + 1}`"
          ></v-text-field>
        </div>

        <div class="group">
          <p class="mb-0">{{ $t("footer") }}</p>
          <v-text-field
            v-for="(line, i) in ticketTemplateLines('footer')"
            :key="`footer${i}`"
            v-model.trim="line[2]"
            hide-details
            :class="{ 'pt-0 mt-0': i === 0 }"
            :placeholder="`${$t('line')} ${i + 1}`"
          ></v-text-field>
        </div>
      </v-card-text>

      <v-card-actions v-if="!loading && mode === 'edit'">
        <v-btn block color="primary" :loading="submitting" @click="submit">
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import EventBus from "@/services/EventBus";

export default {
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      submitting: false,
      mode: "view",
      ticketTemplate: null,
    };
  },
  computed: {
    showDialogLocal: {
      get() {
        return this.showDialog;
      },
      set(value) {
        if (value) {
          return;
        }
        this.$emit("close-dialog");
      },
    },
  },
  mounted() {
    this.getTicketTemplate()
      .then((ticketTemplate) => {
        this.ticketTemplate = ticketTemplate;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.showDialogLocal = false;
          EventBus.$emit("toast", this.$t("no_ticket_template"));
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    ...mapActions(["getTicketTemplate", "updateTicketTemplate"]),
    ticketTemplateLines(key) {
      return this.ticketTemplate.filter((k) => k[0].toLowerCase() === key);
    },
    submit() {
      this.submitting = true;
      this.updateTicketTemplate(this.ticketTemplate)
        .then((ticketTemplate) => {
          this.ticketTemplate = ticketTemplate;
          this.mode = "view";
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};
</script>
