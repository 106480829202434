<template>
  <v-dialog v-model="showDialogLocal" width="600">
    <v-card :class="{ 'text-center': loading }">
      <v-toolbar flat class="pl-2 pr-2 py-0">
        <v-toolbar-title>
          {{ $t("fuel_prices") }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="showDialogLocal = false">
          <v-icon>fa-times</v-icon>
        </v-btn>
      </v-toolbar>

      <v-progress-circular
        v-if="loading"
        color="primary"
        indeterminate
        class="my-5 mx-5"
      ></v-progress-circular>

      <v-card-text v-if="!loading">
        <v-simple-table>
          <tbody>
            <tr v-if="mode === 'edit'">
              <td colspan="2">{{ $t("date") }}</td>
              <td>
                <v-text-field
                  v-model="date"
                  type="date"
                  :min="today"
                  @input="customDate = true"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="time"
                  type="time"
                  @input="customDate = true"
                ></v-text-field>
              </td>
            </tr>
            <tr v-if="mode === 'view' && scheduledPrices">
              <td></td>
              <td class="text-right text--disabled">
                {{ $t("now") }}
              </td>
              <td class="text-right text--disabled">
                {{ scheduledAt }}
              </td>
            </tr>
            <tr v-for="item in currentPrices" :key="item.productCode">
              <td>{{ item.productName }}</td>
              <td class="text-right">{{ item.unitPrice | money(3) }} /l</td>
              <td v-if="mode === 'view' && scheduledPrices" class="text-right">
                {{ getScheduledPrice(item) | money(3) }} /l
              </td>
              <td v-if="mode === 'edit'" colspan="2">
                <v-text-field
                  v-model="item.newUnitPrice"
                  :label="$t('new_price')"
                  type="number"
                  min="0"
                  max="9.999"
                  step="0.001"
                  prefix="€"
                  suffix="/l"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-btn v-if="mode === 'view'" block text @click="mode = 'edit'">
          {{ $t("edit") }}
        </v-btn>

        <v-btn
          v-if="mode === 'edit'"
          text
          :disabled="submitting"
          @click="mode = 'view'"
        >
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          v-if="mode === 'edit'"
          color="primary"
          class="float-right"
          :loading="submitting"
          @click="submit"
        >
          {{ $t("save") }}
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import dayjs from "dayjs";
import { cloneDeep, sortBy } from "lodash-es";
import { mapActions, mapState } from "vuex";
import EventBus from "@/services/EventBus";

export default {
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      submitting: false,
      mode: "view",
      today: null,
      date: null,
      time: null,
      scheduledPrices: null,
      scheduledAt: null,
      currentPrices: null,
      customDate: false,
    };
  },
  computed: {
    ...mapState({
      prices: (state) => state.site.prices,
    }),
    showDialogLocal: {
      get() {
        return this.showDialog;
      },
      set(value) {
        if (value) {
          return;
        }
        this.$emit("close-dialog");
      },
    },
  },
  watch: {
    prices: {
      deep: true,
      handler() {
        this.parsePrices();
      },
    },
    mode: {
      immediate: true,
      handler() {
        this.currentPrices = null;
        this.scheduledPrices = null;
        this.loading = true;

        this.getPrices().then(() => {
          this.parsePrices();
          this.loading = false;
        });
      },
    },
  },
  mounted() {
    this.today = dayjs().format("YYYY-MM-DD");
    this.date = dayjs().format("YYYY-MM-DD");
    this.time = dayjs().add(5, "minute").format("HH:mm");
  },
  methods: {
    ...mapActions(["getPrices", "updatePrices"]),
    getScheduledPrice(product) {
      return this.scheduledPrices.find(
        (p) => p.productCode === product.productCode
      )?.unitPrice;
    },
    parsePrices() {
      const pricesCopy = cloneDeep(this.prices);

      const mappedPrices = pricesCopy.pricelist.map((price) => ({
        ...price,
        newUnitPrice: price.unitPrice / 1000,
      }));

      this.currentPrices = sortBy(mappedPrices, "productName");

      if (pricesCopy.scheduled.length > 0) {
        this.scheduledPrices = sortBy(
          pricesCopy.scheduled[0].pricelist,
          "productName"
        );

        this.scheduledAt = `${this.response.scheduled[0].date} ${this.response.scheduled[0].time}`;
      }
    },
    submit() {
      EventBus.$emit(
        "action",
        "fuel_prices",
        null,
        {
          date: this.customDate ? this.date : null,
          time: this.customDate ? this.time : null,
          pricelist: this.currentPrices.map((price) => ({
            productCode: price.productCode,
            unitPrice: price.newUnitPrice * 1000,
          })),
        },
        () => {
          this.mode = "view";
        }
      );
    },
  },
};
</script>
