export default class TransactionsFilterController {
  static default() {
    return {
      asset: null,
      date_from: null,
      date_till: null,
      dispenser: null,
      brand: null,
      fuel: null,
      operator: "equals",
      amount1: null,
      amount2: null,
      search: null,
      zeroTransactions: false,
    };
  }

  static enabled(filter) {
    return Object.keys(filter)
      .filter((k) => k !== "operator")
      .some((k) => !!filter[k]);
  }

  static reset(filter) {
    Object.keys(filter).forEach((k) => {
      filter[k] = null;
    });
    filter.operator = "equals";
    return filter;
  }
}
