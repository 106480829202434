<template>
  <v-dialog v-model="showDialogLocal" width="420">
    <v-card
      class="transaction-details-dialog"
      :class="{ 'text-center': loading }"
    >
      <v-toolbar flat class="pl-2 pr-2 py-0">
        <v-toolbar-title>{{ $t("transaction_details") }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="showDialogLocal = false">
          <v-icon>fa-times</v-icon>
        </v-btn>
      </v-toolbar>

      <v-progress-circular
        v-if="loading"
        color="primary"
        indeterminate
        class="my-5 mx-5"
      ></v-progress-circular>

      <pre
        v-if="!loading && transactionDetails"
        v-text="transactionDetails"
      ></pre>

      <v-card-text v-if="!loading && !transactionDetails">
        {{ $t("no_transaction_details_found") }}
      </v-card-text>

      <v-card-actions class="d-flex flex-column">
        <v-btn
          v-if="downloadLink"
          block
          text
          color="primary"
          :href="downloadLink"
          target="_blank"
        >
          <v-icon left>fa-file-pdf</v-icon>
          {{ $t("download_pdf") }}
        </v-btn>
        <v-btn block text @click="showDialogLocal = false">
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { mapActions, mapState } from "vuex";

export default {
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    transaction: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      transactionDetails: null,
      downloadLink: null,
    };
  },
  computed: {
    ...mapState({
      selectedSite: (state) => state.site.selectedSite,
    }),
    showDialogLocal: {
      get() {
        return this.showDialog;
      },
      set(value) {
        if (value) {
          return;
        }
        this.$emit("close-dialog");
      },
    },
  },
  mounted() {
    this.getTransactionDetails(this.transaction.id)
      .then((response) => {
        this.transactionDetails = response;
        this.downloadLink = `${process.env.VUE_APP_API_URL}/sites/${this.selectedSite.id}/transactions/${this.transaction.id}/pdf?token=${Vue.prototype.$auth.keycloak.token}&X-Product=${Vue.prototype.$product}`;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    ...mapActions(["getTransactionDetails"]),
  },
};
</script>
