<template>
  <v-expansion-panel class="opts">
    <v-expansion-panel-header>
      {{ $tc("opts", 2) }}
    </v-expansion-panel-header>

    <v-expansion-panel-content v-for="asset in rootAssets" :key="asset.assetId">
      <asset-opt
        :asset="asset"
        :sub-assets="subAssetsForAsset(asset)"
      ></asset-opt>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import AssetOpt from "@/components/Network/SidebarTabs/Assets/AssetOpt.vue";

export default {
  components: { AssetOpt },
  props: {
    group: {
      type: Array,
      required: true,
    },
  },
  computed: {
    rootAssets() {
      return this.group.filter((asset) => !asset.parentOptId);
    },
  },
  methods: {
    subAssetsForAsset(asset) {
      return this.group.filter(
        (subAsset) => subAsset.parentOptId === asset.assetId
      );
    },
  },
};
</script>
