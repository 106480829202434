<template>
  <div class="tree-line">
    <v-chip label outlined small :class="getColor()">
      {{ asset.assetTypeLabel }} {{ getId() }}

      <v-progress-linear
        v-if="asset.assetType === 'TANK' && asset.dynamic"
        :title="getPercentage() | percentage"
        :value="getPercentage()"
        color="grey lighten-1"
      ></v-progress-linear>

      <small v-if="asset.assetType === 'TANK'" class="text--disabled">
        {{ getVolume() | volumeTank }}
      </small>

      <small v-if="asset.assetType === 'TANK'" class="text--disabled ml-2">
        {{ getProductName() }}
      </small>
    </v-chip>
    <v-btn icon x-small @click.stop="openInfo(asset)">
      <v-icon color="grey lighten-1">fa-info-circle</v-icon>
    </v-btn>

    <asset-actions :asset="asset"></asset-actions>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EventBus from "@/services/EventBus";
import AssetActions from "./AssetActions.vue";

export default {
  components: { AssetActions },
  props: {
    asset: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["assetDynamicObject", "assetInfoObject"]),
  },
  methods: {
    getId() {
      return this.assetInfoObject(this.asset.assetId, "id")?.display;
    },
    getColor() {
      const terminal = this.assetDynamicObject(
        this.asset.assetId,
        "terminal"
      )?.value;
      if (terminal === "TEST") {
        return "color-yellow";
      }
      const disabled = this.assetDynamicObject(
        this.asset.assetId,
        "disabled"
      )?.value;
      if (disabled === true) {
        return "color-grey";
      }
    },
    getPercentage() {
      const storageCapacity = this.assetInfoObject(
        this.asset.assetId,
        "Storage_capacity_L"
      ).display;
      const volume = this.assetDynamicObject(
        this.asset.assetId,
        "volume"
      ).display;
      if (!storageCapacity || !volume) {
        return;
      }
      const percentage = (volume / storageCapacity) * 100;
      return Math.round(percentage * 100) / 100;
    },
    getVolume() {
      return this.assetDynamicObject(this.asset.assetId, "volume")?.display;
    },
    getProductName() {
      return this.assetInfoObject(this.asset.assetId, "productName")?.display;
    },
    openInfo() {
      // EventBus.$emit("action", "info", this.asset);
      EventBus.$emit("show-dialog", "info", this.asset);
    },
  },
};
</script>
