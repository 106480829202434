<template>
  <div class="assets">
    <v-expansion-panels v-model="openPanels" multiple>
      <asset-group
        v-if="$productAlarms && pumpAssets.length > 0"
        :group="pumpAssets"
        :name="$tc('pumps', 2)"
      ></asset-group>

      <asset-opt-group
        v-if="optAssets.length > 0"
        :group="optAssets"
        :name="$tc('opts', 2)"
      ></asset-opt-group>

      <asset-group
        v-if="$productAlarms && tankAssets.length > 0"
        :group="tankAssets"
        :name="$t('tanks')"
      ></asset-group>

      <asset-group
        v-if="$productAlarms && priceSignAssets.length > 0"
        :group="priceSignAssets"
        :name="$t('price_signs')"
      ></asset-group>

      <asset-group
        v-if="$productAlarms && siteControllerChildAssets.length > 0"
        :group="siteControllerChildAssets"
        :name="$t('site_controller')"
      ></asset-group>
    </v-expansion-panels>

    <asset-interaction
      v-if="showDialog === 'open'"
      :show-dialog="showDialog === 'open'"
      :dialog-asset="dialogAsset"
      @close-dialog="showDialog = null"
    ></asset-interaction>

    <asset-details-dialog
      v-if="showDialog === 'info'"
      :show-dialog="showDialog === 'info'"
      :dialog-asset="dialogAsset"
      @close-dialog="showDialog = false"
    ></asset-details-dialog>

    <cards-dialog
      v-if="showDialog === 'card'"
      :show-dialog="showDialog === 'card'"
      :dialog-asset="dialogAsset"
      @close-dialog="showDialog = null"
    ></cards-dialog>
  </div>
</template>

<script>
import { orderBy } from "lodash-es";
import { mapActions, mapGetters, mapState } from "vuex";
import EventBus from "@/services/EventBus";
import AssetGroup from "./AssetGroup";
import AssetOptGroup from "./AssetOptGroup";
import AssetDetailsDialog from "./Dialogs/AssetDetailsDialog";
import AssetInteraction from "./Dialogs/AssetInteraction";
import CardsDialog from "./Dialogs/CardsDialog";

export default {
  components: {
    AssetDetailsDialog,
    AssetGroup,
    AssetInteraction,
    AssetOptGroup,
    CardsDialog,
  },
  data() {
    return {
      openPanels: this.$productOpt ? [0] : [],
      showDialog: false,
      dialogAsset: null,
      pumpAssets: [],
      optAssets: [],
      tankAssets: [],
      priceSignAssets: [],
      siteControllerChildAssets: [],
    };
  },
  computed: {
    ...mapGetters(["alertsForAsset", "assetDynamicObject", "assetInfoObject"]),
    ...mapState({
      assets: (state) => state.site.assets,
    }),
  },
  watch: {
    assets: {
      immediate: true,
      handler(assets) {
        const pumpAssets = [];
        const optAssets = [];
        const tankAssets = [];
        const priceSignAssets = [];
        const siteControllerChildAssets = [];

        assets.forEach((asset) => {
          switch (asset.assetType) {
            case "PUMP":
              pumpAssets.push(asset);
              break;
            case "OPT":
              optAssets.push(asset);
              break;
            case "TANK":
              tankAssets.push(asset);
              break;
            case "PRS":
              priceSignAssets.push(asset);
              break;
            default:
              if (asset.parentOptId) {
                optAssets.push(asset);
                break;
              }
              siteControllerChildAssets.push(asset);
          }
        });

        this.pumpAssets = pumpAssets;
        this.optAssets = optAssets;
        this.tankAssets = tankAssets;
        this.priceSignAssets = priceSignAssets;
        this.siteControllerChildAssets = orderBy(
          siteControllerChildAssets,
          "assetTypeLabel"
        );
      },
    },
  },
  mounted() {
    EventBus.$on("show-dialog", (dialog, asset) => {
      this.openDialog(dialog, asset);
    });
  },
  methods: {
    ...mapActions(["doAction"]),
    openDialog(dialog, asset) {
      this.dialogAsset = asset;
      this.showDialog = dialog;
    },
  },
};
</script>
