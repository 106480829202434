<template>
  <v-dialog v-model="showDialogLocal" width="500">
    <v-card class="cards-dialog" :class="{ 'text-center': loading }">
      <v-toolbar flat class="pl-2 pr-2 py-0">
        <v-toolbar-title>
          {{ $t("cards") }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="showDialogLocal = false">
          <v-icon>fa-times</v-icon>
        </v-btn>
      </v-toolbar>

      <v-progress-circular
        v-if="loading"
        color="primary"
        indeterminate
        class="my-5 mx-5"
      ></v-progress-circular>

      <v-card-text v-if="!loading">
        <ul>
          <li v-for="(card, i) in cards" :key="i">
            {{ card }}
          </li>
        </ul>
      </v-card-text>

      <v-card-actions>
        <v-btn block text @click="showDialogLocal = false">
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import EventBus from "@/services/EventBus";

export default {
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    dialogAsset: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      cards: [],
    };
  },
  computed: {
    showDialogLocal: {
      get() {
        return this.showDialog;
      },
      set(value) {
        if (value) {
          return;
        }
        this.$emit("close-dialog");
      },
    },
  },
  mounted() {
    this.getCards(this.dialogAsset.assetId)
      .then((cards) => {
        this.cards = cards;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.showDialogLocal = false;
          EventBus.$emit("toast", this.$t("not_available"));
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    ...mapActions(["getCards"]),
  },
};
</script>
