<template>
  <div>
    <v-menu v-model="menuOpen" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon small>fa-ellipsis-v</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-if="loading">
          <v-progress-circular
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-list-item>

        <v-list-item
          v-for="action in actions"
          v-else
          :key="action.name"
          :disabled="!actionIsAllowed(action)"
          @click.stop="doAction(action)"
        >
          {{ $t(`actions_${action.name}`) }}

          <span v-if="action === 'open' && !geolocation" class="ml-1">
            ({{ $t("no_geolocation") }})
          </span>
          <span v-else-if="action === 'open' && !allowOpeningDoor" class="ml-1">
            ({{ $t("too_far_away") }})
          </span>
        </v-list-item>

        <v-list-item v-if="!loading && actions.length === 0" disabled>
          {{ $t("no_actions_available") }}
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapState } from "vuex";
import EventBus from "@/services/EventBus";

export default {
  props: {
    asset: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      actions: null,
      menuOpen: false,
    };
  },
  computed: {
    ...mapState({
      geolocation: (state) => state.app.geolocation,
      selectedSite: (state) => state.site.selectedSite,
    }),
    allowOpeningDoor() {
      return (
        this.selectedSite.distance &&
        this.selectedSite.distance < Vue.prototype.$geofencingRadius / 1000
      );
    },
  },
  watch: {
    menuOpen(value) {
      if (!value) {
        return;
      }
      this.loading = true;
      this.getAssetActions(this.asset.assetId).then((actions) => {
        this.actions = actions;
        this.loading = false;
      });
    },
  },
  methods: {
    ...mapActions(["getAssetActions"]),
    actionIsAllowed(action) {
      if (!action.enabled) {
        return false;
      }

      if (action.name === "open") {
        return this.geolocation && this.allowOpeningDoor;
      }

      return true;
    },
    doAction(action) {
      switch (action.name) {
        case "card":
          EventBus.$emit("show-dialog", "card", this.asset);
          break;
        case "open":
          EventBus.$emit("show-dialog", "open", this.asset);
          break;
        default:
          EventBus.$emit("action", action.name, this.asset.assetId);
      }

      this.menuOpen = false;
    },
  },
};
</script>
