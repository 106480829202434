<template>
  <v-dialog v-model="showDialogLocal" :width="dynamicInfo ? 800 : 400">
    <v-card class="asset-details-dialog">
      <v-toolbar flat class="pl-2 pr-2 py-0">
        <v-toolbar-title>
          {{ $t("asset_details") }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close-dialog')">
          <v-icon>fa-times</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="12" :md="dynamicInfo ? 6 : 12">
            <v-simple-table>
              <tbody>
                <tr v-for="item in staticInfo" :key="item.key">
                  <td :title="item.key">
                    {{ item.label }}
                  </td>
                  <td :title="item.value">
                    {{ item.display }} {{ item.unit }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>

          <v-col v-if="dynamicInfo" cols="12" md="6">
            <v-simple-table>
              <tbody>
                <tr v-for="item in dynamicInfo" :key="item.key">
                  <td :title="item.key">
                    {{ item.label }}
                  </td>
                  <td :title="item.value">
                    {{ item.display }} {{ item.unit }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn block text @click="$emit('close-dialog')">
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    dialogAsset: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["assetById"]),
    showDialogLocal: {
      get() {
        return this.showDialog;
      },
      set(value) {
        if (value) {
          return;
        }
        this.$emit("close-dialog");
      },
    },
    staticInfo() {
      return this.assetById(this.dialogAsset.assetId).info;
    },
    dynamicInfo() {
      return this.assetById(this.dialogAsset.assetId).dynamic;
    },
  },
};
</script>
