<template>
  <div>
    <v-row no-gutters>
      <v-col cols="2" sm="1" class="opt-icon-col">
        <v-icon class="opt-icon" :class="getOptClass(asset)">
          $vuetify.icons.opt
        </v-icon>
      </v-col>

      <v-col cols="3" sm="2" lg="3" xl="2">
        <small>{{ getNode() }}</small>
      </v-col>

      <v-col cols="5" sm="4" md="5" xl="3">
        <div v-if="showPaper(asset)">
          <small class="mb-0 text--secondary">
            {{ getPaperPercentageUsed() | percentage }}
          </small>
          <v-progress-linear
            :value="getPaperPercentageUsed()"
            color="grey lighten-1"
            height="6"
          ></v-progress-linear>
        </div>
      </v-col>

      <v-col cols="2" sm="5" md="4" lg="3" xl="6" class="text-right">
        <v-btn icon x-small @click.stop="openInfo(asset)">
          <v-icon color="grey lighten-1">fa-info-circle</v-icon>
        </v-btn>
        <asset-actions :asset="asset"></asset-actions>
      </v-col>
    </v-row>

    <div class="tree">
      <asset
        v-for="subAsset in subAssets"
        :key="subAsset.assetId"
        :asset="subAsset"
      ></asset>
    </div>

    <alert
      v-for="alert in alertsForAsset(asset.assetId)"
      :key="alert.id"
      :alert="alert"
      :show-site-name="false"
      open-details
      small-badge
      class="mt-3"
    ></alert>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Alert from "@/components/Global/Alert.vue";
import EventBus from "@/services/EventBus";
import Asset from "./Asset";
import AssetActions from "./AssetActions";

export default {
  components: { Alert, Asset, AssetActions },
  props: {
    asset: {
      type: Object,
      required: true,
    },
    subAssets: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["alertsForAsset", "assetDynamicObject", "assetInfoObject"]),
  },
  methods: {
    getNode() {
      const yaliiNode = this.assetInfoObject(
        this.asset.assetId,
        "yaliiNode"
      )?.display;
      if (yaliiNode) {
        return yaliiNode;
      }
      return this.assetInfoObject(this.asset.assetId, "node")?.display;
    },
    getOptClass() {
      const optColor = this.assetDynamicObject(
        this.asset.assetId,
        "opt_color"
      )?.display;
      const ledColor = this.assetDynamicObject(
        this.asset.assetId,
        "led_color"
      )?.display;
      if (!optColor || !ledColor) {
        return;
      }
      return `opt-icon-opt-${optColor} opt-icon-led-${ledColor}`;
    },
    showPaper() {
      const model = this.assetInfoObject(this.asset.assetId, "model")?.display;
      return model === "MPT-2" || model === "MPT2";
    },
    getPaperPercentageUsed() {
      return this.assetDynamicObject(
        this.asset.assetId,
        "paper_roll_fraction_total"
      )?.display;
    },
    openInfo() {
      EventBus.$emit("show-dialog", "info", this.asset);
    },
  },
};
</script>
