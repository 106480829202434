<template>
  <v-dialog v-model="showDialogLocal" width="500">
    <v-stepper v-if="dialogAsset" v-model="dialogStep">
      <v-stepper-header>
        <v-stepper-step :complete="dialogStep > 1" step="1"></v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="dialogStep > 2" step="2"></v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <p v-if="error" class="error--text">{{ error }}</p>
          <p v-else>{{ $t("dispenser_interaction_1") }}</p>
          <v-btn text @click="$emit('close-dialog')">{{ $t("cancel") }}</v-btn>
          <v-btn
            color="primary"
            class="float-right"
            :disabled="!!error"
            @click="dialogStep = 2"
          >
            {{ $t("continue") }}
          </v-btn>
        </v-stepper-content>
        <v-stepper-content step="2">
          <p v-if="error" class="error--text">{{ error }}</p>
          <p v-else>{{ $t("dispenser_interaction_2") }}</p>
          <v-btn block text @click="$emit('close-dialog')">{{
            $t("close")
          }}</v-btn>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    dialogAsset: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialogStep: 1,
      error: false,
    };
  },
  computed: {
    showDialogLocal: {
      get() {
        return this.showDialog;
      },
      set(value) {
        if (value) {
          return;
        }
        this.$emit("close-dialog");
      },
    },
  },
  watch: {
    dialogStep: {
      immediate: true,
      handler(value) {
        if (value === 1) {
          this.doAction({
            assetId: this.dialogAsset.assetId,
            action: "opt/identify",
          })
            .then(() => {
              this.error = false;
            })
            .catch((error) => {
              this.error = error.response.data.detail;
            });
        }
        if (value === 2) {
          this.doAction({
            assetId: this.dialogAsset.assetId,
            action: "opt/opendoor",
          })
            .then(() => {
              this.error = false;
            })
            .catch((error) => {
              this.error = error.response.data.detail;
            });
        }
      },
    },
    showDialog(value) {
      if (value) {
        return;
      }
      this.dialogStep = 1;
    },
  },
  methods: {
    ...mapActions(["doAction"]),
  },
};
</script>
