<template>
  <div class="alarms">
    <div
      v-if="alerts.length === 0"
      class="text--secondary py-5 px-5 no-recent-alerts"
    >
      <v-icon>fa-check-circle</v-icon>
      <small>{{ $t("no_recent_alarms") }}</small>
    </div>

    <v-list v-if="alerts.length > 0">
      <template v-for="a in alerts">
        <v-list-item :key="a.key" class="py-2">
          <alert
            :key="a.id"
            :alert="a"
            :margin="1"
            :show-site-name="false"
            small-badge
          ></alert>
        </v-list-item>
      </template>
    </v-list>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Alert from "@/components/Global/Alert";

export default {
  components: {
    Alert,
  },
  computed: {
    ...mapGetters(["alertsForSite"]),
    ...mapState({
      selectedSite: (state) => state.site.selectedSite,
    }),
    alerts() {
      return this.alertsForSite(this.selectedSite.id);
    },
  },
};
</script>
