var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"transactions",class:{
    'filter-enabled': _vm.filterIsEnabled,
    'filter-open': _vm.filterIsOpen,
    'filter-pinned': _vm.filterPinned,
  }},[_c('v-card',[_c('v-expansion-panels',{staticClass:"transactions-filter",model:{value:(_vm.filterOpen),callback:function ($$v) {_vm.filterOpen=$$v},expression:"filterOpen"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pl-3"},[_c('span',[_c('v-btn',{staticClass:"mr-3",attrs:{"icon":"","x-small":""},on:{"click":function($event){$event.stopPropagation();_vm.filterPinned = !_vm.filterPinned}}},[_c('v-icon',{attrs:{"x-small":"","color":_vm.filterPinned ? 'primary' : 'grey lighten-1'}},[_vm._v(" fas fa-thumbtack ")])],1),_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":"","color":_vm.filterIsEnabled ? 'primary' : 'grey lighten-1'}},[_vm._v(" fas fa-filter ")]),_vm._v(" "+_vm._s(_vm.$t("filter"))+" ")],1)]),_c('v-expansion-panel-content',[_c('transactions-filter',{attrs:{"filter":_vm.filter}})],1)],1)],1),_vm._l((_vm.transactionsByDate),function(t){return _c('v-list-item',{key:t.id,staticClass:"py-3 with-separator",class:[
        t.amount === 0 && t.status !== 'No error' ? 'null-transaction' : '',
        t.addedAsync ? 'added-async' : '',
      ],on:{"click":function($event){return _vm.showDialogForTransaction(t)}}},[_c('div',[_c('span',[_vm._v(_vm._s(_vm._f("date")(t.date)))]),_c('span',[_vm._v(" "+_vm._s(_vm._f("time")(t.date))+" "),_c('span',{staticClass:"text--disabled"},[_vm._v(" "+_vm._s(_vm.$tc("pumps"))+" "+_vm._s(t.dispenser_number)+" ")])])]),_c('div',[(t.opt_number)?_c('span',[_vm._v(_vm._s(t.opt_number))]):_vm._e(),(!['cash', 'none'].includes(t.brand.toLowerCase()))?_c('span',{staticClass:"text--disabled"},[_vm._v(" "+_vm._s(_vm._f("cardref")(t.cardref))+" ")]):_vm._e()]),_c('div',[_c('span',[_vm._v(_vm._s(_vm._f("money")(t.amount,2)))]),_c('span',{staticClass:"text--disabled"},[_vm._v(" "+_vm._s(t.brand)+" ")])]),_c('div',[(t.fuel.name.toLowerCase() === 'cng')?_c('span',[_vm._v(" "+_vm._s(_vm._f("weightTransaction")(t.volume))+" ")]):_c('span',[_vm._v(_vm._s(_vm._f("volumeTransaction")(t.volume,2, t.unit)))]),_c('span',{staticClass:"text--disabled"},[_vm._v(" "+_vm._s(t.fuel.name)+" ")])]),(t.amount === 0 && t.status !== 'No error')?_c('p',{staticClass:"my-0"},[_c('small',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(t.status))])]):_vm._e()])}),(_vm.allowLoadMore)?_c('v-btn',{attrs:{"block":"","plain":"","x-large":""},on:{"click":_vm.loadMore}},[_vm._v(" "+_vm._s(_vm.$t("load_more"))+" ")]):_vm._e(),(_vm.transactions.length === 0)?_c('v-list-item',{staticClass:"py-3 text--disabled"},[_vm._v(" "+_vm._s(_vm.$t("no_results"))+" ")]):_vm._e()],2),(_vm.showDialog)?_c('transaction-details-dialog',{attrs:{"show-dialog":_vm.showDialog,"transaction":_vm.dialogTransaction},on:{"close-dialog":function($event){_vm.showDialog = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }